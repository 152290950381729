import imagesLoaded from 'imagesloaded';
import * as PIXI from 'pixi.js';
import { Assets } from 'pixi.js';
// import { PixelateFilter } from 'pixi-filters';
import { DotFilter } from 'pixi-filters';
import PixiPlugin from '../lib/PixiPlugin.min';
gsap.registerPlugin(PixiPlugin) 
PixiPlugin.registerPIXI(PIXI);

export function mosaicImg(target) {
	let _w = target.offsetWidth;
	let _h = target.offsetHeight;
	let _res = window.devicePixelRatio || 1;

	const app = new PIXI.Application({
		width: _w,
		height: _h,
		backgroundAlpha: 0,
		resolution: window.devicePixelRatio || 1,
		autoDensity: true,
		resizeTo: target,
		antialias: true,
	});
	target.appendChild(app.view);
	const url = target.querySelector('img').getAttribute('src');
	Assets.load(url);
	let img;
	const setup = () => {
		img = PIXI.Sprite.from(url);
		img.width = _w;
		img.height = _h;

		app.stage.addChild(img);
	};

	const fragment = `
	precision highp float;
	varying vec2 vTextureCoord;
	uniform sampler2D uSampler;
	uniform vec4 outputFrame;
	uniform float msize;
	void main() {
		vec2 vUv2 = vTextureCoord;
		vUv2.x = floor(vUv2.x * outputFrame.z / msize) / (outputFrame.z / msize) + (msize / 2.0) / outputFrame.z;
		vUv2.y = floor(vUv2.y * outputFrame.w / msize) / (outputFrame.w / msize) + (msize / 2.0) / outputFrame.w;
		vec4 color = texture2D(uSampler, vUv2);
		gl_FragColor = color;
	}
	`;
	let _mosaic = 40;
	const applyMosaicEffect = (sprite, _mosaic) => {
		let uniforms = {
			msize: _mosaic,
		};
		const mosaicF = new PIXI.Filter(PIXI.defaultVertex, fragment, uniforms);	
		if (_mosaic > 1) {
			sprite.filters = [mosaicF];
			_mosaic -= 1;
		}else{
			sprite.filters = [];
		}
	};
	setup();

	app.ticker.add(function(delta){
		if (_mosaic > 1) {
			_mosaic -= 1;
			applyMosaicEffect(img, _mosaic);
		}else{
			img.filters = [];
		}
	});

	window.addEventListener('resize', resize);
	function resize() {
		_w = target.offsetWidth;
		_h = target.offsetHeight;	
		img.width = _w;
		img.height = _h;
	}

};

