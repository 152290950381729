import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
import ScrambleTextPlugin from '../lib/ScrambleTextPlugin.min';
import SplitText from '../lib/SplitText.min';
import DrawSVGPlugin from '../lib/DrawSVGPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ScrambleTextPlugin, SplitText, DrawSVGPlugin);

import ShuffleText from '../lib/shuffle-text.min';

import Swiper from '../lib/swiper-bundle.min';

import {mosaicImg} from './mosaic.js';

import lity from 'lity';
import 'lity/dist/lity.css';


export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		cancelAnimationFrame(timeReq);
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');


function nav_show(){
	body.addClass('navopen');
	const _txef = document.querySelectorAll('#main_nav .txef');
	txtEffect(_txef);
}
function nav_hide(){
	body.removeClass('navopen');
}
function navInit(){
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
	$('#main_nav_close').on('click', function(){
		nav_hide();
	});
}


let EL_scrollTrigger;
function elFunc(EL){
	gsap.utils.toArray(EL).forEach(target => {
		target.style.opacity = 0;
		EL_scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top bottom-=10%",
			once: true,
			onEnter: self => {
				elAnime(target);
			},
		});
		const navh = document.getElementById('nav').clientHeight;
		const up_scrollTrigger = ScrollTrigger.create({
			trigger: 'body',
			start: "top top",
			onUpdate: self => {
				const _slfscrl = self.scroll();
				if(self.direction == -1){
					document.body.classList.remove('scrldown');
				}else{
					if(_slfscrl > navh){
						if(self.prevSclrl < _slfscrl-1){
							document.body.classList.add('scrldown');
						}
						self.prevSclrl = _slfscrl;
					}
				}
			},
		});		
	});
}

function elAnime(target){
	target.style.opacity = 1;
	if(target.classList.contains('elp')){
		var elc = target.querySelectorAll('.elc');
	}else{
		var elc = [target];
	}
	elc.forEach((elc, i) => {
		let _opa = 1;
		gsap.fromTo(elc,{opacity:_opa},{
			opacity:1,
			onStart: function(){
				const _txef = elc.querySelectorAll('.txef');
				if(_txef){
					txtEffect(_txef);
				}
				elc.classList.add('elon');
			},
		});
	});
}

function elMove(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.mv').forEach((_tar, i) => {
		const _elmc = _tar.querySelectorAll('.mvc');
		gsap.fromTo(_elmc,
			{ opacity:0, y:60, stagger: 0.2 },
			{ opacity:1, y:0, duration: 0.6, stagger: 0.2,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom-=10%",
					onRefresh: self => self.progress === 1 && self.animation.progress(1)
				}
			}
		);
	});
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


var tar_offset;
function pageScroll(){
	if(window.matchMedia('(max-width:767px)').matches){
		tar_offset = 50;
	}else{
		tar_offset = 70;
	}
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		nav_hide();
		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
		}
	});
}


function txtEffectInit (target){
	gsap.utils.toArray(target).forEach(el => {
		el.style.opacity = 0;
	});
}
function txtEffect (target){
	gsap.utils.toArray(target).forEach(el => {
		const txel = new ShuffleText(el);
		el.style.opacity = 1;
		const parent = el.parentElement;
		if(parent.classList.contains('morebtn')){
			parent.style.opacity = 1;
		}
		txel.start();
	});
}



function worksHover(){
	const txtlis = document.querySelectorAll('#cliant_txt a');
	const logolis = document.querySelectorAll('#cliant_logo a');
	const hover = (target) => {
		const lis = target.querySelectorAll('a');
		lis.forEach((item, i) => {
			item.addEventListener('mouseenter', (e) => {
				txtlis[i].classList.add('active');
				logolis[i].classList.add('active');
			});
			item.addEventListener('mouseleave', (e) => {
				txtlis[i].classList.remove('active');
				logolis[i].classList.remove('active');
			});
		});
	};
	document.querySelectorAll('.wlist').forEach((item, i) => {
		hover(item);
	});
}


function switchBF (){
	const tri1 = document.getElementById('bftri1');
	const tri2 = document.getElementById('bftri2');
	const _from = () => {
		return {
			autoAlpha:0, x:-60,
			duration: 0.1,
		};
	}
	const _to = () => {
		return {
			autoAlpha:1, x:0,
			duration: 0.2,
			stagger: 0.05,
		}
	}

	const tl1 = gsap.timeline({
		scrollTrigger: {
			trigger: tri1,
			start: "top center",
			end: "bottom center+=10%",
			toggleActions: "play reverse play reverse", 
		}
	})
	.fromTo('#bf1 .swe', _from(), _to())

	const tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: tri2,
			start: "top center-=10%",
			end: "bottom center",
			toggleActions: "play none play reverse", 
		}
	})
	.fromTo('#bf2 .swe', _from(), _to(), '<')
}


function switchPH (){
	const tri1 = document.getElementById('phtri1');
	const tri2 = document.getElementById('phtri2');
	const _from = () => {
		return {
			autoAlpha:0,
			duration: 0.1,
		};
	}
	const _to = () => {
		return {
			autoAlpha:1,
			duration: 0.3,
		}
	}

	const tlbg = gsap.timeline({
		defaults: {
			duration: 0.8,
		},
		scrollTrigger: {
			trigger: '#top_philosophy',
			start: "top top",
			end: "bottom center",
			toggleActions: "play none play reverse", 
			once: true,
		}
	})
	.fromTo('#phbg path', {drawSVG:'0%'}, {drawSVG:'100%', stagger: 0.1})

	const tl1 = gsap.timeline({
		scrollTrigger: {
			trigger: tri1,
			start: "top top",
			end: "bottom center+=20%",
			toggleActions: "play reverse play reverse", 
		}
	})
	.fromTo('#ph1 .tit', _from(), _to())
	.fromTo('#ph1 .swe', _from(), _to(), '<');

	const tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: tri2,
			start: "top center-=20%",
			end: "bottom bottom",
			toggleActions: "play none play reverse", 
		}
	})
	.fromTo('#ph2 .tit', _from(), _to())
	.fromTo('#ph2 .swe', _from(), _to(), '<');
}


function copyEffect (){
	gsap.timeline({
		defaults: {
			duration: 1,
		},
		scrollTrigger: {
			trigger: '#copy',
			start: "top center",
			end: "bottom center",
			scrub: 1,
		}
	})
	.to('#copyslide', {color:"#fff"})
	.to('body', {backgroundColor:"#000"}, '<')
}


function mosaicImgFunc(){
	document.querySelectorAll('.mosa').forEach((item, i) => {
		EL_scrollTrigger = ScrollTrigger.create({
			trigger: item,
			start: "top bottom-=10%",
			once: true,
			onEnter: self => {
				mosaicImg(item);
			},
		});
	});	
}


function tabFunc() {
	const _tabs = $('.tab');
	const _btns = $('#tab_nav li');
	const hash = location.hash;
	let initindex;
	if (hash.match(/#certification/)){
		initindex = 0;
	} else if (hash.match(/#information-security/)){
		initindex = 1;
	} else if (hash.match(/#privacy/)){
		initindex = 2;
	} else {
		initindex = 0;
	}
	_btns.eq(initindex).addClass('active');
	_tabs.eq(initindex).addClass('active');

	_btns.on('click', function(){
		_btns.removeClass('active');
		_tabs.removeClass('active');
		const index = _btns.index(this);
		$(this).addClass('active');
		_tabs.eq(index).addClass('active');
	})
}


function bfFunc (){
	document.querySelectorAll('.bf_sec').forEach((_tar, i) => {
		const _swes = _tar.querySelectorAll('.swe');
		gsap.timeline({
			defaults: {
				duration: 0.4
			},
			scrollTrigger: {
				trigger: _tar,
				start: "top center",
				end: "bottom bottom",
			}
		})
		.fromTo(_swes,
			{ opacity:0, x:-60, stagger: 0.1 },
			{ opacity:1, x:0, stagger: 0.1 }
		);
	});

	gsap.timeline({
		defaults: {
			duration: 1,
		},
		scrollTrigger: {
			trigger: '#bf_sec2',
			start: "top center",
			end: "top center-=20%",
			scrub: 1,
		}
	})
	.to('#business-field', {backgroundColor:"#000"})
}

function phFunc (){
	document.querySelectorAll('.phbg').forEach((_tar, i) => {
		const _path = _tar.querySelectorAll('path');
		const tlbg = gsap.timeline({
			defaults: {
				duration: 0.8,
			},
			scrollTrigger: {
				trigger: _tar,
				start: "top top",
				end: "bottom bottom",
				toggleActions: "play none play reverse",
				once: true,
			}
		})
		.fromTo(_path, {drawSVG:'0%'}, {drawSVG:'100%', stagger: 0.1})
	});

	gsap.timeline({
		defaults: {
			duration: 1,
		},
		scrollTrigger: {
			trigger: '#vision_mission',
			start: "top center",
			end: "top center-=20%",
			scrub: 1,
		}
	})
	.to('#philosophy', {backgroundColor:"#f1f1f1"});

}


function kvsvgAnime(){
	const set_kvtxtEffect= () => {
		gsap.utils.toArray('#kv_txt .txef').forEach(el => {
			el.style.opacity = 0;
			const parent = el.parentElement;
			if(parent.classList.contains('morebtn')){
				parent.style.opacity = 0;
			}
		});
	}
	const kvtxtEffect= () => {
		gsap.utils.toArray('#kv_txt .txef').forEach(el => {
			const txel = new ShuffleText(el);
			el.style.opacity = 1;
			const parent = el.parentElement;
			if(parent.classList.contains('morebtn')){
				parent.style.opacity = 1;
			}
			txel.start();
		});
	}

	const _logo_pc = document.getElementById('logomask');
	const _logo_sp = document.getElementById('logomask_sp');

	let _parts_pc = [];
	for (let i = 1; i <= 14; i++) {
		const _v = document.getElementById('v' + i);
		_parts_pc.push(_v);
	}
	let _parts_sp = [];
	for (let i = 1; i <= 11; i++) {
		const _v = document.getElementById('vsp' + i);
		_parts_sp.push(_v);
	}

	const kvsvg = document.getElementById('kvsvg');
	const elmPosset= (_parts,_logo) => {
		kvsvg.classList.remove('fin');
		gsap.set(_logo, {
			autoAlpha:0,
			xPercent:100,
			yPercent:-100,
		});	
		_parts.forEach((_v,i) => {
			gsap.set(_v, {
				autoAlpha:0,
				x:"random("+winW*-1+", "+winW+", 10)",
				y:"random("+winH*-1+", "+winH+", 10)",
			});
		});
		elmPos(_parts,_logo);
	}
	const elmPos = (_parts,_logo) => {
		_parts.forEach((_v,i) => {
			gsap.to(_v, {
				autoAlpha:1,
				x:0,
				y:0,
				duration: 0.8,
				delay: i * 0.1,
				ease: "power4.inOut",
				onComplete: () => {
					if (i === _parts.length-6) {
						gsap.to(_logo, {
							autoAlpha:1,
							xPercent:0,
							yPercent:0,
							duration: 0.8,
							ease: "power4.inOut",
							onComplete: () => {
								kvtxtEffect();
							}
						});
					}
					if (i === _parts.length-1) {
						kvsvg.classList.add('fin');
					}
				}
			});
		});
	}

	let mm = gsap.matchMedia();
	mm.add("(min-width: 768px)", () => {
		set_kvtxtEffect();
		elmPosset(_parts_pc,_logo_pc);
	});
	mm.add("(max-width: 767px)", () => {
		set_kvtxtEffect();
		elmPosset(_parts_sp,_logo_sp);
	});

}


function pagekvAnime(){
	const pagekvsvg = document.getElementById('pagekvsvg');
	if( !pagekvsvg ){
		return false;
	}
	let _parts = [];
	gsap.utils.toArray('#pagekvsvg .v').forEach(_v => {
		_parts.push(_v);
	});

	const elmPos = () => {
		_parts.forEach((_v,i) => {
			const _x = gsap.utils.random(-100, 100, 10);
			const _y = gsap.utils.random(-100, 100, 10);
			gsap.fromTo(_v,
				{opacity:0,x:_x,y:_y},
				{
					opacity:1,
					x:0,
					y:0,
					rotate: '0.0001deg',
					duration: 0.8,
					delay: i * 0.01,
					ease: "power4.inOut",
					onComplete: () => {
						if (i === _parts.length-1) {
							pagekvsvg.classList.add('fin');
						}
					}
			});
		});
	}
	elmPos();
}


var PageEvents = {
	loadFunc: function(){
		navInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		mosaicImgFunc();
		pageScroll();
		txtEffectInit('.txef');
		elFunc('.el');
		elMove();
		pagekvAnime();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		// kvAnime();
		switchBF();
		switchPH();
		worksHover();
		copyEffect();
		kvsvgAnime();
	},
	businessfieldFunc(){
		bfFunc();
	},
	philosophyFunc(){
		phFunc();
	},
	securityFunc: function(){
		tabFunc();
	}
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceBogo = function(data){
		let target = '#wrapper';
		if(data){
			target = data.next.html;
		}
		const $newPageBogo = $(target).find('.bogo-language-switcher').html();
		body.find('#bogo').html($newPageBogo);
		$('#bogo').find('a').addClass('nopj');
	}
	replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}
	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll() {
		await new Promise((resolve, reject) => {
			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					if(window.matchMedia('(max-width:767px)').matches){
						tar_offset = 50;
					}else{
						tar_offset = 70;
					}					
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						resolve();
					}});
				}else{
					gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0.6, scrollTo:0, ease:'power2', onComplete:function(){
					resolve();
				}});
			}
		})
	}

	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});
	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll();
			pageCheck();
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded().done(function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('business-field') ){
		PageEvents.businessfieldFunc();
	}
	if( document.getElementById('philosophy') ){
		PageEvents.philosophyFunc();
	}
	if( document.getElementById('security') ){
		PageEvents.securityFunc();
	}
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		$('#container').imagesLoaded({background:true}, function() {
			setTimeout(function(){
				PageEvents.loadFunc();
				pageCheck();
				pjaxSettings();
				body.addClass('loaded');
			}, 600);
		});
	});
}

initialize();


})();
})( jQuery );

}